import { useEffect, useState } from "react";
import { Col, Row } from "antd";

import { GET } from "../../../providers/useAxiosQuery";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../providers/CustomTableFilter";
import TableEvaluation from "./components/TableEvaluation";

export default function PageReports() {
	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at_formatted",
		sort_order: "desc",
	});

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/form?${new URLSearchParams(tableFilter)}`,
		"form_list"
	);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter({
			...tableFilter,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		});
	};

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	return (
		<Row gutter={[12, 12]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<div className="tbl-top-filter">
					<div
						style={{
							display: "flex",
							gap: 8,
						}}
					>
						<TableGlobalSearchAnimated
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</div>

					<div
						style={{
							display: "flex",
							gap: 12,
						}}
					>
						<TableShowingEntriesV2 />

						<TablePageSize
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</div>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<TableEvaluation
					dataSource={dataSource}
					onChangeTable={onChangeTable}
				/>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<div className="tbl-bottom-filter">
					<TableShowingEntriesV2 />
					<TablePagination
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
						setPaginationTotal={dataSource?.data.total}
						showLessItems={true}
						showSizeChanger={false}
						tblIdWrapper="tbl_wrapper"
					/>
				</div>
			</Col>
		</Row>
	);
}
