import { useNavigate } from "react-router-dom";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table } from "antd";

import { apiUrl } from "../../../../providers/companyInfo";

export default function TableEvaluation(props) {
	const { dataSource, onChangeTable } = props;

	const navigate = useNavigate();

	return (
		<Table
			dataSource={dataSource && dataSource.data ? dataSource.data.data : []}
			rowKey={(record) => record.id}
			pagination={false}
			bordered={false}
			onChange={onChangeTable}
			scroll={{ x: "max-content" }}
			className="tbl-main-primary"
		>
			<Table.Column
				title="Action"
				key="action"
				dataIndex="action"
				align="center"
				render={(_, record) => {
					return (
						<>
							<Button
								type="link"
								className="btn-print"
								onClick={() => {
									window.open(
										apiUrl(`api/evaluation_print/${record.id}`),
										"_blank"
									);
								}}
							>
								<FontAwesomeIcon icon={faPrint} />
							</Button>
						</>
					);
				}}
			/>
			<Table.Column
				title="Created"
				key="created_at_formatted"
				dataIndex="created_at_formatted"
				sorter={true}
				defaultSortOrder="descend"
			/>
			<Table.Column title="Year" key="year" dataIndex="year" sorter={true} />
			<Table.Column
				title="Semester"
				key="semester"
				dataIndex="semester"
				sorter={true}
			/>
			<Table.Column
				title="Form Name"
				key="form_name"
				dataIndex="form_name"
				sorter={true}
				render={(text, record) => {
					return (
						<Button
							type="link"
							className="p-0 main-primary-color"
							onClick={() => navigate(`/evaluation/view/${record.id}`)}
						>
							{text}
						</Button>
					);
				}}
			/>
		</Table>
	);
}
